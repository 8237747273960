<template>
    <proveedoresList />

</template>

<script>

const proveedoresList = () => import('@/layouts/components/Proveedores/ProveedoresList.vue')

export default {
  components: {
    proveedoresList,
  },
}
</script>

<style>

</style>
